import styled from 'styled-components'
import fonts from '../../../constants/fonts'
import colors from '../../../constants/colors'

export const IconGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 6rem 0;
`
export const IconGroupId = styled.div`
  color: ${colors.BLUE};
  font-size: ${fonts.FONTTITLELARGE};
  font-weight: ${fonts.WEIGHTSEMI};
  margin-left: 10px;
`
export const IconGroupTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
`
export const IconGroupText = styled.div`
  font-size: ${fonts.TEXTMEDIUM};
  font-weight: ${fonts.WEIGHTREGULAR};
  margin: 0 auto;
  max-width: 64rem;
  text-align: center;
`
