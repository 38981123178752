import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import IconCustom from '../../Base/Icon/Custom'

import MEDIA from '../../../constants/media'
import COLORS from '../../../constants/colors'
import FONTS from '../../../constants/fonts'
import Text from '../../Base/Text'

const RowWrap = styled.div`
  margin: 60px 0;
`
const Row = styled.div`
  margin: auto;
  padding: 0 20px;
  max-width: 1040px;
  ${MEDIA.medium`
    display: flex;
    justify-content: space-between;
  `}
`
const RowTitle = styled.h2`
  font-size: ${FONTS.TITLETWO};
  font-weight: ${FONTS.WEIGHTREGULAR};
  text-align: center;
  margin: 0 0 30px 0;
  padding: 0 20px;
`
const ColNum = styled.h3`
  font-size: ${FONTS.TITLETHREE};
  font-weight: ${FONTS.WEIGHTSEMI};
  color: ${COLORS.BLUE};
  padding: 0 10px;
  margin: -5px 0 0 0;
`
const ColText = styled.div`
  margin: 0;
`
const ColTitle = styled.h4`
  color: ${COLORS.BLUE};
  font-size: ${FONTS.TITLEFOUR};
  font-weight: ${FONTS.WEIGHTMEDIUM};
  margin: 0 0 5px 0;
`
const ColDescription = styled.div`
  margin: 3rem auto 0;
  max-width: 38rem;
`
const Column = styled.div`
  margin: 0 auto 3rem;
  padding: 0 3rem;
  text-align: center;
  &:last-child {
    margin-bottom: 0;
  }
`
// End Icon Row Components

const IconRow = props => (
  <RowWrap
    style={{
      backgroundColor: props.background,
    }}
  >
    {props.rowTitle ? <RowTitle>{props.rowTitle}</RowTitle> : null}
    <Row
      style={{
        textAlign: props.alignment,
      }}
    >
      {props.columns.map(column => (
        <IconCol key={column.id} {...column} />
      ))}
    </Row>
  </RowWrap>
)

IconRow.propTypes = {
  columns: PropTypes.array.isRequired,
}

const IconCol = props => (
  <Column id={props.id}>
    <ScrollAnimation animateIn='fadeInUp' animateOnce>
      <div>
        <IconCustom theme={props.colIcon} />
      </div>
    </ScrollAnimation>
    <ScrollAnimation animateIn='fadeIn' delay={4}>
      <ColDescription>
        {props.colNum ? <ColNum>{props.colNum}</ColNum> : null}
        <ColText>
          {props.colTitle ? <ColTitle>{props.colTitle}</ColTitle> : null}
          <Text theme='columns'>{props.colDesc}</Text>
        </ColText>
      </ColDescription>
    </ScrollAnimation>
  </Column>
)

IconCol.propTypes = {
  id: PropTypes.string.isRequired,
}

export default IconRow
