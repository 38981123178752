import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'
import styled from 'styled-components'
import { ButtonLink } from '../../StyledComponents'
import Title from '../../Base/Title'

// ColorCta Components
const SimpleCtaWrapper = styled.div`
  margin: 60px auto;
  padding: 0 20px;
  max-width: 80rem;
  text-align: center;
`
// End ColorCta Components

const SimpleCta = (props) => (
  <SimpleCtaWrapper>
    <ScrollAnimation animateIn='fadeInUp' animateOnce>
      <Title level={3} margined>
        {props.title}
      </Title>
    </ScrollAnimation>
    <ScrollAnimation animateIn='fadeInUp' animateOnce>
      <ButtonLink to={props.link}>{props.linkTitle}</ButtonLink>
    </ScrollAnimation>
  </SimpleCtaWrapper>
)

export default SimpleCta
