import styled from 'styled-components'
import MEDIA from '../../../constants/media'
import COLORS from '../../../constants/colors'

const TwoColumn = styled.div`
  text-align: center;
  margin: auto;
  img {
    border: 1px solid ${COLORS.BORDERS};
    -webkit-box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.25); 
    box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.25);
    margin-right: 20px;
    width: 100%;
    max-width: 461px;
    flex-shrink: 0; /*  fixes weird ie img in flex */
  }
  p {
    max-width: 32rem;
    margin: 2rem auto 4rem;
  }
  ${MEDIA.medium`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    max-width: none;
    text-align: left;
    p {
      margin: 0;
    }
  `}
`
export default TwoColumn
