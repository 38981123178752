import React from 'react'
import {
  graphql,
} from 'gatsby'
import Layout from '../../components/Layout'
import SEO from '../../components/SEO'

import {
  Page, Main, ColorWrap,
} from '../../components/StyledComponents'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import PageHero from '../../components/Blocks/PageHero'
import IconRow from '../../components/Blocks/IconRow'
import IconGroup from '../../components/Blocks/IconGroup'
import ImgCheckIn from '../../assets/images/how-it-works/checkIn.jpg'
import ImgTakeTime from '../../assets/images/how-it-works/takeTime.jpg'
import ImgPatientRelaxing from '../../assets/images/how-it-works/patientRelaxing.jpg'
import ImgAddNotes from '../../assets/images/how-it-works/addNotes.jpg'
import ImgCentralizedRecords from '../../assets/images/how-it-works/centralizedRecords.jpg'
import ImgInfoCaptured from '../../assets/images/how-it-works/infoCaptured.jpg'
import ImgSymptomsRecord from '../../assets/images/how-it-works/symptomsRecord.jpg'
import ImgATransferResults from '../../assets/images/how-it-works/transferResults.jpg'
import SimpleCta from '../../components/Blocks/SimpleCta'
import Section from '../../components/Base/Section'
import IconCustom from '../../components/Base/Icon/Custom'
import Paragraph from '../../components/Base/Paragraph'
import SectionWrap from '../../components/Base/Section/Wrap'
import TwoColumn from '../../components/Blocks/TwoColImage'
import BottomOverlay from '../../components/Blocks/BottomOverlay'
import './index.css'

export const query = graphql`
  query {
    mobileImage: file(relativePath: { eq: "page-hero/hero-how-it-works-small.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    desktopImage: file(
      relativePath: { eq: "page-hero/hero-how-it-works.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ({
  data,
}) => {
  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: '(min-width: 800px)',
    },
  ]
  return (
    <Layout>
      <SEO
        title='How it Works'
        description='Your job just got easier. Introducing your personal electronic medical record assistant.'
      />
      <Page>
        <Header />
        <Main>
          <PageHero
            background={sources}
            title='How It Works'
          />
          <Section center>
            <div className='how-it-works-video-container'>
              <iframe
                className='how-it-works-video'
                src="https://www.youtube.com/embed/tpIuQ2Bwgkc" 
                title="How It Works video"
              ></iframe>
            </div>
            <IconGroup
              groupIcon={<IconCustom theme='first' />}
              groupId='First'
              groupDesc='Patients answer questions posed by our intake software in a mobile app as part of the patient intake process.'
            />
            <div>
              <TwoColumn>
                <img src={ImgCheckIn} alt='Check In' />
                <Paragraph>
                  During the check-in process, the front desk staff at your healthcare organization select questionnaires in the ChartMedic mobile app for patients to complete prior to seeing their healthcare provider.
                </Paragraph>
              </TwoColumn>
              <TwoColumn>
                <img src={ImgTakeTime} alt='Take Time' />
                <Paragraph>
                  In the waiting and/or exam room, patients are asked questions pertinent to their visit and answer them in the easy-to-use ChartMedic app. No writing or typing to fill out forms is required!
                </Paragraph>
              </TwoColumn>
              <TwoColumn>
                <img src={ImgPatientRelaxing} alt='Patient Relaxing' />
                <Paragraph>
                  Patients relax while answers are transferred to the cloud, and chart-ready draft documentation is generated for their provider to review immediately prior to or during the appointment.
                </Paragraph>
              </TwoColumn>
            </div>
          </Section>
          <SectionWrap theme='grey'>
            <Section center>
              <IconGroup
                groupIcon={<IconCustom theme='second' />}
                groupId='Second'
                groupDesc='Clinic staff review clinical information in chart-ready draft documentation in our web app.'
              />
              <TwoColumn>
                <img src={ImgInfoCaptured} alt='Info Captured' />
                <Paragraph>
                  With important clinical details provided via the tablet, clinicians readily have a complete picture of what the patient is experiencing.
                </Paragraph>
              </TwoColumn>
              <TwoColumn>
                <img src={ImgSymptomsRecord} alt='Symptom Record' />
                <Paragraph>
                  Information is presented as symptoms with characteristics and as contextual information such as medication use, past medical history, and pertinent risk factors.
                </Paragraph>
              </TwoColumn>
            </Section>
          </SectionWrap>
          <Section center>
            <IconGroup
              groupIcon={<IconCustom theme='third' />}
              groupId='Third'
              groupDesc="Chart notes generated from the patient's interview and easily transferred to Epic."
            />
            <TwoColumn>
              <img src={ImgATransferResults} alt='Transfer Results' />
              <Paragraph>
               With charting done, providers can transfer results to central hospital systems with a few keystrokes.
              </Paragraph>
            </TwoColumn>
            <TwoColumn>
              <img src={ImgCentralizedRecords} alt='Centralized Records' />
              <Paragraph>
                Patient records are centralized in Epic to ensure easy, ongoing access.
              </Paragraph>
            </TwoColumn>
          </Section>
          <ColorWrap Color='gray'>
            <IconRow
              rowTitle='We do it all with important things in mind.'
              alignment='center'
              columns={[
                {
                  id: 'secure',
                  colIcon: 'lock',
                  colTitle: 'Secure.',
                  colDesc:
                  'No personally identifying patient information is recorded by the ChartMedic app, stored by ChartMedic, or transferred between ChartMedic and the electronic medical record. Icons and the patient\'s birth year are used to associate interviews with each patient.',
                },
                {
                  id: 'easy-to-use',
                  colIcon: 'thumbsup',
                  colTitle: 'Easy-to-Use.',
                  colDesc:
                  'The app is user-friendly with large fonts and simple prompts, which helps simplify the intake experience. Questions are multiple-choice, so patients do not have to type long-form responses.',
                },
                {
                  id: 'multi-language',
                  colIcon: 'languages',
                  colTitle: 'Language Support.',
                  colDesc:
                  'Questionnaires are available in multiple languages for easy access by patients with limited English skills. This decreases the time spent using medical interpreters, improves the patient experience.',
                },
              ]}
            />
          </ColorWrap>
          <SimpleCta
            title='All of this improves care delivery with less time and money spent.'
            link='/benefits'
            linkTitle='Learn About More Benefits'
          />
        </Main>
      </Page>
      <Footer />
      <BottomOverlay />
    </Layout>
  )
}
