import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'

import {
  IconGroupWrapper,
  IconGroupId,
  IconGroupTop,
  IconGroupText,
} from './styles'

const IconGroup = props => (
  <IconGroupWrapper>
    <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
      <IconGroupTop>
        {props.groupIcon}
        <IconGroupId>{props.groupId}</IconGroupId>
      </IconGroupTop>
      <IconGroupText>{props.groupDesc}</IconGroupText>
    </ScrollAnimation>
  </IconGroupWrapper>
)

export default IconGroup
